/* @import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap'); */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "P22 Mackinac";
  src: url("../fonts/P22Mackinac.woff2") format("woff2"),
    url("../fonts/P22Mackinac.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "P22 Mackinac";
  src: url("../fonts/P22Mackinac-Medium.woff2") format("woff2"),
    url("../fonts/P22Mackinac-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

html {
  background: #f3f4f7;
  font-family: "P22 Mackinac" !important;
  /* font-family: 'Work Sans', sans-serif !important; */
  overflow: auto !important;
  padding-right: unset !important;
  position: unset !important;
}

.sidebar-container::-webkit-scrollbar {
  width: 5px;
  border-radius: 21px;
  height: 3px;
  display: none;
}

.sidebar-container::-webkit-scrollbar-track {
  background: #18202f !important;
}

/* Handle */
.sidebar-container::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: linear-gradient(89.99deg, #3a47ab 0.01%, #4a56db 98.9%) !important;
}

/* Handle on hover */
.sidebar-container::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(89.99deg, #3a47ab 0.01%, #4a56db 98.9%) !important;
}

.horizontal-card-scrollbar::-webkit-scrollbar {
  height: 7px;
}
.horizontal-card-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
.horizontal-card-scrollbar::-webkit-scrollbar-thumb {
  background: #4a57da;
  border-radius: 10px;
}

.demo-editor {
  height: 400px !important;
  padding: 0 10px;
  border: 1px solid #f1f1f1;
  margin-top: 10px;
  border-radius: 2px;
}

.disabled-editor {
  background: #fafafa;
  cursor: default;
}

.disabled-toolbar {
  display: none !important;
}

@media screen and (max-width: 576px) {
  .demo-editor {
    min-height: 200px;
  }
}
@media screen and (max-width: 321px) {
  .demo-editor {
    min-height: 150px;
  }
}
.rh5v-PlayPause_component svg {
  margin: auto;
  width: 30px;
  height: 30px;
}
.rh5v-PlayPause_component:hover {
  background-color: unset !important;
}
.rh5v-Volume_component svg {
  margin: auto;
  width: 30px;
  height: 30px;
}
.rh5v-Fullscreen_component svg {
  margin: auto;
  width: 30px;
  height: 30px;
}
.rh5v-Fullscreen_component:hover {
  background-color: unset !important;
}
.rh5v-DefaultPlayer_component,
.rh5v-DefaultPlayer_video {
  border: 1.8px solid rgba(0, 0, 0, 0.7);
  /*border-radius: 15px;*/
}
.rh5v-DefaultPlayer_controls {
  /*border-bottom-left-radius: 15px;*/
  /*border-bottom-right-radius: 15px;*/
}

.rdrDefinedRangesWrapper {
  display: none;
}

input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

/*FILTER SCROLL*/

.filter-container::-webkit-scrollbar {
  width: 5px;
  border-radius: 21px;
  height: 3px;
}

.filter-container::-webkit-scrollbar-track {
  background: #d7d7da !important;
}

/* Handle */
.filter-container::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: linear-gradient(89.99deg, #b4b4b4 0.01%, #b4b4b4 98.9%) !important;
}

/* Handle on hover */
.filter-container::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(89.99deg, #b4b4b4 0.01%, #b4b4b4 98.9%) !important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h1 {
  font-size: 2em !important;
  font-weight: bolder !important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h2 {
  font-weight: bolder !important;
  font-size: 1.5em !important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h3 {
  font-weight: bolder !important;
  font-size: 1.7em !important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h4 {
  font-size: 1em !important;
  font-weight: bolder !important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h5 {
  font-size: 0.83em !important;
  font-weight: bolder !important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h6 {
  font-size: 0.67em !important;
  font-weight: bolder !important;
}

.selected {
  background: lightgray;
  border-radius: 15px;
  padding: 12px;
}

.unselected {
  padding: 12px;
  opacity: 0.3;
}

.chart canvas {
  height: 163px !important;
}

.webkit_in {
  display: -webkit-inline-box;
}

.webkit_out {
  display: table-caption;
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-left,
.apexcharts-legend.apx-legend-position-right,
.apexcharts-legend.apx-legend-position-left {
  display: none !important;
}

/* CUSTOM COMMON RADIO BUTTON STYLE */
input[type='radio'] {
  accent-color: #434fc5;
}

.ckeditor_outer {
  height: 500px !important;
}

.cke_contents {
  height: 390px !important;
}

@media only screen and (max-width: 1400px) and (min-width: 1320px) {
  /* .dashboard_sec2_custom {
    width: 55%;
    margin-right: 20px;
  } */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.custom-scrollbar::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #ccc; /* Gray track color */
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #171d2f; /* Blue thumb color */
  border-radius: 10px;
}

/* phone change */

.PhoneInputInput {
  background: inherit;
}

/* date picker dark mode */
.dark .rdrCalendarWrapper {
  background: #171d2f;
}

.dark .rdrDateInput {
  background: #171d2f;
  border: none;
}

.dark .rdrDateDisplayWrapper {
  background: #27264d;
}

.dark .rdrDateDisplayItemActive input {
  color: white;
}

.dark .rdrDayDisabled {
  background: #27264d;
}
.dark .rdrDayNumber span {
  color: white !important;
}

.dark .rdrMonthPicker select,
.dark .rdrYearPicker select {
  color: white;
}

.dark .rdrMonthPicker select option,
.dark .rdrYearPicker select option {
  background: #27264d;
}

/* apex chart on dark mode */
.dark .apexcharts-yaxis-label tspan,
.dark .apexcharts-xaxis-label tspan,
.dark .apexcharts-text {
  fill: #fff;
  background: #27264d;
}

.dark .apexcharts-tooltip,
.dark .apexcharts-tooltip-title {
  background: #27264d !important;
  /* fill: red !important; */
}

/* select dark */

.dark .css-1fdsijx-ValueContainer,
.dark .css-13cymwt-control,
.dark .select__menu,
.dark .css-1hb7zxy-IndicatorsContainer,
.dark .css-qbdosj-Input,
.dark .select__control {
  background-color: #27264d;
  border: #27264d;
}

.dark .select__multi-value {
  background: #171d2f;
}

.dark .select__multi-value__label {
  color: white;
}

.css-1fdsijx-ValueContainer,
.css-13cymwt-control {
  border-radius: 0.375rem !important;
}

/* ck editor */
.dark .cke_top {
  background: #3a47ab;
}

.dark .ckeditor_outer {
  filter: invert(80%);
}

.dark input {
  color-scheme: dark;
}

/* phone input dark mode */

.dark .PhoneInputCountrySelect {
  background-color: #171d2f;
}

select {
  appearance: none;
}

.recharts-radial-bar-background-sector {
  fill: #808080;
  opacity: 20%;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #3a47ab !important;
  font-size: 12px;
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 12px !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: 0.25;
  color: #3a47ab !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button.slick-arrow.slick-prev {
  display: none !important;
}
button.slick-arrow.slick-next {
  display: none !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.add-survey-part .add-survey-card .css-13cymwt-control,
.add-survey-part .add-survey-card .css-t3ipsp-control {
  min-height: 41px !important;
  background-color: #f4f4f4 !important;
  border: 1.5px solid #e7e8ea !important;
  border-radius: 0.5rem !important;
}

.css-1u9des2-indicatorSeparator {
  width: 0px !important;
}

.react-calendar__tile--active {
  display: flex;
  align-items: center;
  justify-content: end;
}

.css-13cymwt-control {
  background-color: #f4f4f4 !important;
  border: 1.5px solid #e7e8ea !important;
  border-radius: 0.5rem !important;
}

.css-13cymwt-control .css-hlgwow,
.css-t3ipsp-control .css-hlgwow {
  padding: 4px 20px 4px 8px !important;
}

.css-1jqq78o-placeholder {
  color: #a1a1a1 !important;
}

/* .css-t3ipsp-control:hover, */
.css-t3ipsp-control {
  background-color: #f4f4f4 !important;
  border: 1px solid #e7e8ea !important;
  box-shadow: none !important;
  border-radius: 0.5rem !important;
}

.css-1xc3v61-indicatorContainer {
  padding-right: 16px !important;
}

.css-1xc3v61-indicatorContainer svg {
  color: #0b1d2e !important;
}

.rounded-answer-choice {
  width: 14px;
  height: 14px;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ced2d5;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.rounded-answer-choice:checked {
  background-color: #4a56db;
  box-shadow: inset 0 0 0 3px white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #f4f4f4 inset;
}

.jodit-status-bar-link{
  display: none;
}
